
<script setup lang="ts">
const excerpt = undefined
  defineExpose({ frontmatter: {}, excerpt: undefined })
  
import IconBadge from '@/components/design-system/icons/IconBadge.vue'
import StackComponent from '@/components/design-system/layout/StackComponent.vue';

import {
  vkiLogoInFavour,
  vkiLogoAgainst,
} from '@/components/design-system/icons';
import { useI18n } from 'vue-i18n';

const { t, locale } = useI18n();

</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body">
<stack-component spacing="extra-small">
<p><icon-badge :icon="vkiLogoAgainst" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-secondary-fg))&#x27;"></icon-badge> Nevyhovujúci príklad</p>
<p><icon-badge :icon="vkiLogoInFavour" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-primary-fg))&#x27;"></icon-badge> Vyhovujúci príklad</p>
</stack-component>
<h2>1. Otázka sa musí týkať toho, čo majú zvolení politici možnosť ovplyvniť.</h2>
<stack-component spacing="extra-small">
<p><icon-badge :icon="vkiLogoAgainst" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-secondary-fg))&#x27;"></icon-badge> Česká republika by mala vystúpiť z EÚ (otázka v regionálnych voľbách).</p>
<p><icon-badge :icon="vkiLogoInFavour" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-primary-fg))&#x27;"></icon-badge> Česká republika by mala vystúpiť z EÚ (otázka v celoštátnych voľbách).</p>
<p><icon-badge :icon="vkiLogoInFavour" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-primary-fg))&#x27;"></icon-badge> Chcel/a by som, aby sa Praha v nasledujúcich voľbách vrátila k systému jedného volebného obvodu (otázka v pražských voľbách).</p>
</stack-component>
<h2>2. Na otázku treba odpovedať áno alebo nie a nemala by viesť k odpovedi.</h2>
<p>Pri navrhovaní otázok si musíme vedieť predstaviť, že ľudia si vyberú odpovede áno aj nie. Otázka by tiež nemala byť sugestívna (návodná).</p>
<br>
<stack-component spacing="extra-small">
<p><icon-badge :icon="vkiLogoAgainst" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-secondary-fg))&#x27;"></icon-badge> Mala by sa zlepšiť dopravná situácia v Považskom Chlmci a vo Vraní?</p>
<p><icon-badge :icon="vkiLogoAgainst" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-secondary-fg))&#x27;"></icon-badge> Bol/a by som rád/a, keby Opencard nebola pre cestujúcich nákladovo výhodnejšia ako “papierová električenka”, aby si cestujúci mohli vybrať. (otázka navádza k odpovedi “áno”).</p>
<p><icon-badge :icon="vkiLogoInFavour" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-primary-fg))&#x27;"></icon-badge> Malo by sa pri Rajčianke vybudovať odpočívadlo? (len za predpokladu, že je všeobecne známe, čo to je, veľa sa o tom v meste diskutovalo, vedia, koľko by to stálo atď.).</p>
<p><icon-badge :icon="vkiLogoInFavour" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-primary-fg))&#x27;"></icon-badge> Malo by sa na cyklodopravu vyčleniť aspoň 1 % z rozpočtu mesta na dopravu?</p>
</stack-component>
<h2>3. Otázky by mali byť dôležité</h2>
<p>Zohľadňujeme napríklad to, na čo dané zastupiteľstvo/parlament vynakladá najviac verejných prostriedkov.</p>
<p>Témy vyberáme tak, aby boli relevantné pre všetky skupiny voličov (vek, príjem, záujmy).</p>
<br>
<stack-component spacing="extra-small">
<p><icon-badge :icon="vkiLogoAgainst" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-secondary-fg))&#x27;"></icon-badge> Malo by sa mesto ospravedlniť pani M. Lorenzovej a pánovi Cejthamrovi za výroky, ktoré zazneli počas tejto konfrontácie?</p>
<p><icon-badge :icon="vkiLogoInFavour" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-primary-fg))&#x27;"></icon-badge> Hlasovali by ste za zrušenie záruky a automatický vstup do druhého piliera?</p>
<p><icon-badge :icon="vkiLogoInFavour" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-primary-fg))&#x27;"></icon-badge> Hlasovali by ste za dôveru vláde a zachovanie eura?</p>
</stack-component>
<h2>4. Otázka by mala byť čo najkonkrétnejšia</h2>
<p>Ide o to, aby bolo možné skontrolovať odpoveď po 4 rokoch (na konci volebného obdobia).</p>
<br>
<stack-component spacing="extra-small">
<p><icon-badge :icon="vkiLogoAgainst" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-secondary-fg))&#x27;"></icon-badge> Mala by sa zahusťovať zástavba v centre mesta?</p>
<p><icon-badge :icon="vkiLogoAgainst" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-secondary-fg))&#x27;"></icon-badge> Podľa môjho názoru by mala byť vybudovaná rýchlostná cesta z ruzyňského letiska do centra Prahy. (Správna odpoveď by bola: Budem presadzovať/chcem, aby sa v nasledujúcich 4 rokoch začala stavať rýchlodráha z letiska do centra Prahy)</p>
<p><icon-badge :icon="vkiLogoInFavour" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-primary-fg))&#x27;"></icon-badge> Mala by sa cena listkov na verejnú dopravu znížiť o 10 % pre seniorov a študentov?</p>
</stack-component>
<h2>5. Otázka musí byť krátka a zrozumiteľná</h2>
<p>Chceme, aby si respondent otázky skutočne prečítal a porozumel im. Preto ich formulujeme v rozsahu maximálne 20 slov, pričom akýkoľvek popis obmedzujeme na 50 slov.</p>
<br>
<stack-component spacing="extra-small">
<p><icon-badge :icon="vkiLogoAgainst" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-secondary-fg))&#x27;"></icon-badge> Hlasovali by ste za:<br>
zrušenie sociálneho príspevku pre rodičov samoživiteľov,
zníženie príspevku pri narodení dieťaťa len u rodičov s nízkymi príjmami,
jednotnú celkovú výšku rodičovského príspevku (220 000Kč) a väčšiu flexibilitu pri výbere výšky a dĺžky poberania rodičovského príspevku,
zníženie príspevku na starostlivosť,
zníženie dávky v nezamestnanosti a zrušenie možnosti príplatku k dávke vo výške minimálneho zárobku,
zavedenie príspevku na začatie podnikania,
sprísnenie podmienok nároku na nemocenské a materské pre samostatne zárobkovo činné osoby?</p>
<p><icon-badge :icon="vkiLogoInFavour" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-primary-fg))&#x27;"></icon-badge> Súhlasím so zavedením turniketov v metre.</p>
</stack-component>
<h2>6. Otázky s popismi musia mať zmysel aj bez nich</h2>
<p>Veľký počet ľudí číta len samotnú otázku, nie popis. Môže sa stať, že popis sa na mobilnom zariadení nezobrazí.</p>
<br>
<stack-component spacing="extra-small">
<p><icon-badge :icon="vkiLogoAgainst" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-secondary-fg))&#x27;"></icon-badge> Hlasovali by ste za zrušenie záruky a automatický vstup do druhého piliera?</p>
<p><icon-badge :icon="vkiLogoAgainst" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-secondary-fg))&#x27;"></icon-badge> Má podľa vás zmysel pripájať sa k podobným projektom?</p>
<p><icon-badge :icon="vkiLogoInFavour" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-primary-fg))&#x27;"></icon-badge> Súhlasím so spoplatnením vjazdu áut do centra mesta, napríklad formou mýta.</p>
</stack-component>
<h2>7. Uprednostňujeme otázky v pozitívnej forme, vyhýbame sa mätúcej forme dvojitému záporu</h2>
<p>Nasledujúci príklad ukazuje, že dvojitý zápor je zavádzajúci a nepresný.</p>
<br>
<p><icon-badge :icon="vkiLogoAgainst" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-secondary-fg))&#x27;"></icon-badge> Na Vysočine by nemalo byť úložisko jadrového odpadu za každú cenu.</p>
<br>
<p>V skutočnosti tu nie je jasné, s čím opýtaný nesúhlasí:</p>
<p>a) nie, nemalo by sa stavať,</p>
<p>b) nesúhlasím, malo by sa vybudovať.</p>
<p>Správne formulovaná otázka by mala znieť: “Mal by kraj urobiť všetko pre to, aby zabránil vybudovaniu úložiska jadrového odpadu?”).</p>
<br>
<p><icon-badge :icon="vkiLogoInFavour" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-primary-fg))&#x27;"></icon-badge> Stredné školy by sa mali naďalej zlučovať do väčších celkov.</p>
<br>
<p>Nie “Stredné školy by sa už nemali spájať do väčších celkov”.</p>
<h2>8. Otázka musí byť napísaná zrozumiteľným jazykom</h2>
<p>Otázky sú formulované tak, aby im porozumeli rôzne sociálne skupiny.</p>
<br>
<stack-component spacing="extra-small">
<p><icon-badge :icon="vkiLogoAgainst" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-secondary-fg))&#x27;"></icon-badge> Nový územný plán musí regulovať výškovú výstavbu, ktorá môže poškodiť panorámu Prahy.</p>
<p><icon-badge :icon="vkiLogoInFavour" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-primary-fg))&#x27;"></icon-badge> Výstavba mrakodrapov v historickom centre by mala byť zakázaná.</p>
</stack-component>
<h2>9. Vyberáme aj témy, ktoré sú zaujímavé, aj keď nie úplne dôležité.</h2>
<stack-component spacing="extra-small">
<p><icon-badge :icon="vkiLogoInFavour" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-primary-fg))&#x27;"></icon-badge> Hlasovali by ste za návrh na farebnú signalizáciu pre poslancov, ako majú hlasovať?</p>
<p><icon-badge :icon="vkiLogoInFavour" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-primary-fg))&#x27;"></icon-badge> Hlasovali by ste za zákon “Václav Havel prispel k slobode a demokracii.”?</p>
</stack-component>
</div>
</template>
